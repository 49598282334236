import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiTokenDto, ApiTokenMetaDto, ClientAdminUpdateDto, ClientDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import { ClientQueryKeys } from "../client";

const ADMIN_CLIENT_PATH = "admin/client";

export const useAdminDeleteClientLogo = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => apiDelete(`${ADMIN_CLIENT_PATH}/logo`),
        onSuccess: () => {
            queryClient.invalidateQueries(ClientQueryKeys.current);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CLIENT_LOGO_DELETED));
        },
    });
};

export const useAdminUpdateClientLogo = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (input: FormData) => apiPatch(`${ADMIN_CLIENT_PATH}/logo`, input),
        onSuccess: () => {
            queryClient.invalidateQueries(ClientQueryKeys.current);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CLIENT_LOGO_UPDATED));
        },
    });
};

export const useAdminUpdateClient = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ ...changes }: ClientAdminUpdateDto) => apiPatch<ClientDto>(ADMIN_CLIENT_PATH, changes),
        onSuccess: () => {
            queryClient.invalidateQueries(ClientQueryKeys.current);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CLIENT_UPDATED));
        },
    });
};

export const useGenerateScimToken = () => {
    return useMutation({
        mutationFn: (period: number) => apiPost<ApiTokenDto>("admin/client/scim-token", { period }),
    });
};

export const useScimTokenMeta = () => {
    return useQuery({
        queryFn: ({ signal }) => apiGet<ApiTokenMetaDto>("admin/client/scim-token", { signal }),
    });
};
