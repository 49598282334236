import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, Divider, FormControl, IconButton, Stack, Typography } from "@mui/material";
import { GroupDto, nonNullable, UpdateGroupRequestBody, UserDto, zUpdateGroupRequestBody } from "api-shared";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import SearchInput from "../../../components/input/SearchInput";
import SingleUserButton from "../../../components/user/SingleUserButton";

import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { useUpdateGroupMutation } from "../../../domain/admin/groups";
import { useDebounce } from "../../../hooks/useDebounce";
import { compareUsersByDisplayName } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";

type UserDialogProps = {
    open: boolean;
    currentUserIds: number[];
    allUsers: UserDto[];
    onClose: () => void;
    currentGroup: GroupDto;
};

export const UserDialog = ({ open, currentUserIds, onClose, allUsers, currentGroup }: UserDialogProps) => {
    const { t } = useTranslation();

    const [searchKey, setSearchKey] = useState("");

    const debouncedSearchKey = useDebounce(searchKey);

    const users = allUsers
        .filter((user) => user.displayname?.toLowerCase().includes(debouncedSearchKey.toLowerCase()))
        .toSorted(compareUsersByDisplayName);

    const {
        handleSubmit,
        control,
        formState: { isValid, isDirty },
        setValue,
        watch,
        reset,
    } = useForm<Pick<UpdateGroupRequestBody, "userIds">>({
        mode: "onChange",
        resolver: zodResolver(zUpdateGroupRequestBody.pick({ userIds: true })),
        defaultValues: { userIds: currentUserIds },
    });

    const updateGroupsMutation = useUpdateGroupMutation();

    const onSubmit = handleSubmit((data) => {
        updateGroupsMutation.mutate({ groupId: currentGroup.id, ...currentGroup, userIds: data.userIds }, { onSuccess: handleClose });
    });

    function handleClose() {
        onClose();
        reset({ userIds: currentUserIds });
    }

    const getSelectedList = () => {
        const currentValues = watch("userIds");
        const filteredUserList = currentValues
            .map((item) => allUsers.find((u) => u.id === item) ?? null)
            .filter(nonNullable)
            .toSorted(compareUsersByDisplayName);
        return filteredUserList.map((user) => (
            <Stack key={user.id} direction="row" justifyContent="space-between" alignItems="space-between" py={0.5}>
                <SingleUserButton user={user} translate={t} />
                <IconButton
                    onClick={() =>
                        setValue(
                            "userIds",
                            currentValues.filter((val) => val !== user.id),
                            { shouldValidate: true, shouldDirty: true, shouldTouch: true },
                        )
                    }
                >
                    <DeleteRounded />
                </IconButton>
            </Stack>
        ));
    };

    return (
        <ActionItemDialog
            open={open}
            title={t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE)}
            primary={t(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid || !isDirty}
            onClose={handleClose}
            translate={t}
            disableContentPadding
            maxWidth="md"
        >
            <Stack direction="row" height="100%">
                <Stack px={3} py={2} width="100%">
                    <Typography fontWeight="medium" color="GrayText" variant="overline">
                        {t(translationKeys.VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE)}
                    </Typography>
                    <Form onSubmit={onSubmit} style={{ margin: 0 }}>
                        <Stack gap={1}>
                            <SearchInput onChange={(input) => setSearchKey(input)} searchKey={searchKey} translate={t} />
                            <Controller
                                name="userIds"
                                control={control}
                                render={({ field }) => (
                                    <FormControl>
                                        {users.map((user) => (
                                            <Stack
                                                key={user.id}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="space-between"
                                                py={0.5}
                                            >
                                                <SingleUserButton user={user} translate={t} />
                                                <Checkbox
                                                    size="small"
                                                    checked={field.value.includes(user.id)}
                                                    onChange={() => {
                                                        if (!field.value.includes(user.id)) {
                                                            field.onChange([...field.value, user.id]);
                                                            return;
                                                        }
                                                        const updatedList = field.value.filter((u) => u !== user.id);
                                                        field.onChange(updatedList);
                                                    }}
                                                    inputRef={field.ref}
                                                />
                                            </Stack>
                                        ))}
                                    </FormControl>
                                )}
                            />
                        </Stack>
                    </Form>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack px={3} py={2} width="100%">
                    <Typography fontWeight="medium" color="GrayText" variant="overline">
                        {t(translationKeys.VDLANG_ADMIN_GROUPS_ASSIGNED_USERS)}
                    </Typography>
                    {getSelectedList()}
                </Stack>
            </Stack>
        </ActionItemDialog>
    );
};
