import { GroupDto, GroupType } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import EditIcon from "../../../components/icons/EditIcon";
import TableIconButton from "../../../components/table/TableIconButton";
import { translationKeys } from "../../../translations/main-translations";

interface GroupTableEditCellProps {
    cell: CellProps<GroupDto>;
    onClick: (value: any) => void;
}

export const GroupTableEditCell = ({ cell, onClick }: GroupTableEditCellProps) => {
    const { t } = useTranslation();
    const label = t(translationKeys.VDLANG_ADMIN_GROUPS_EDIT_BUTTON);
    const { type } = cell.row.original;

    let tooltip = label;
    if (type !== GroupType.STANDARD) {
        tooltip = t(translationKeys.VDLANG_ADMIN_GROUPS_SPECIAL_DISABLED);
    }

    const disabled = type !== GroupType.STANDARD;

    return (
        <TableIconButton onClick={() => onClick(cell.value)} disabled={disabled} title={tooltip} aria-label={label}>
            <EditIcon />
        </TableIconButton>
    );
};
