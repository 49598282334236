import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Divider, Paper, Stack, Typography, styled } from "@mui/material";
import { AdminUserDto, IdentityProviderType, UserStatus, nonNullable, type IdentityProviderConfigPassword } from "api-shared";
import { TFunction } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, SortByFn } from "react-table";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import SearchInput from "../../../components/input/SearchInput";
import BaseTable from "../../../components/table/BaseTable";
import TableConstantsCell from "../../../components/table/TableConstantsCell";
import TableDateCell from "../../../components/table/TableDateCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useAdminDomains } from "../../../domain/admin/domains";
import { useGroups } from "../../../domain/admin/groups";
import { resolveIdentityProvider, useAdminIdentityProviders } from "../../../domain/admin/signon";
import {
    useAdminDeleteUser,
    useAdminInviteExistingUser,
    useAdminInviteUser,
    useAdminResendInvitationUser,
    useAdminUpdateUser,
    useAdminUserResetPassword,
    useAdminUsers,
    useLastLogins,
} from "../../../domain/admin/users";
import { useSeatLimits } from "../../../domain/seat-limit";
import { useCurrentUserId } from "../../../domain/users";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import { useLanguage } from "../../../hooks/useLanguage";
import { USER_STATUS_FIELD_NAME, mapConstantsToTranslations } from "../../../lib/fields";
import { formatUser } from "../../../lib/formatters";
import { getFullGroupPath } from "../../../lib/groups";
import { translateFromProperty } from "../../../lib/translate";
import { translationKeys } from "../../../translations/main-translations";
import EditMemberDialog from "./EditMemberDialog";
import InviteMemberDialog from "./InviteMemberDialog";
import InvitedAtTableCell from "./InvitedAtTableCell";
import PasswortResetTableCell from "./PasswortResetTableCell";
import { SeatLimit, SeatLimitContainer } from "./SeatLimit";

const Root = styled(Stack)(({ theme }) => ({
    height: "100%",
}));

const TableRoot = styled(Paper)({ height: "100%" });

const userStatusOrder = [
    UserStatus.STATUS_ACTIVE,
    UserStatus.STATUS_INVITED,
    UserStatus.STATUS_INACTIVE,
    UserStatus.STATUS_REGISTERED,
    UserStatus.STATUS_DELETED,
];

const INITIAL_SORT_BY = [
    {
        id: "name",
        desc: false,
    },
];

const compareByStringLength: SortByFn<AdminUserDto> = (aRow, bRow, columnId) => {
    const a = aRow.values[columnId];
    const b = bRow.values[columnId];
    const cmp = a.length - b.length;
    return cmp || a.localeCompare(b);
};

const getStatusTooltip = (translate: TFunction) =>
    userStatusOrder
        .map((status) => mapConstantsToTranslations(USER_STATUS_FIELD_NAME, status))
        .filter(nonNullable)
        .map((translationKey) => (
            <p key={translationKey}>
                <strong>{translate(translationKey)}</strong>
                {`: `}
                {translate(`${translationKey}_HINT`)}
            </p>
        ));

const MembersSettings = () => {
    const updateUserInfoMutation = useAdminUpdateUser();
    const lastLoginsQuery = useLastLogins();
    const inviteUserMutation = useAdminInviteUser();
    const deleteUserMutation = useAdminDeleteUser();
    const resendUserInvitationMutation = useAdminResendInvitationUser();
    const resetUserPasswordMutation = useAdminUserResetPassword();
    const inviteExistingUserMutation = useAdminInviteExistingUser();

    const { t: translate } = useTranslation();
    const language = useLanguage();
    const identityProviderQuery = useAdminIdentityProviders();
    const adminUsersQuery = useAdminUsers();
    const groupsQuery = useGroups();
    const loggedInUserId = useCurrentUserId();
    const userSeatsList = useSeatLimits();
    const domains = useAdminDomains();

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const passwordResetDialog = useDialog();
    const editUserDialog = useDialog();
    const deleteUserDialog = useDialog();
    const inviteDialog = useDialog();

    const [filter, setFilter] = useState("");
    const tableFilterValue = useDebounce(filter);

    const usersData = adminUsersQuery.data ?? [];
    const users = usersData.filter((u) => u.status !== UserStatus.STATUS_DELETED);
    const selectedUser = users.find((user) => user.id === selectedUserId);

    const updateUser = (changes: Partial<AdminUserDto>) => {
        if (selectedUserId === null) {
            return;
        }
        updateUserInfoMutation.mutate({ id: selectedUserId, ...changes, email: changes.email ?? undefined });
        setSelectedUserId(null);
        editUserDialog.close();
    };

    const resetUserPassword = () => {
        if (selectedUser === undefined) {
            return;
        }
        resetUserPasswordMutation.mutate(selectedUser.id);
        setSelectedUserId(null);
        passwordResetDialog.close();
    };

    const deleteUser = () => {
        if (selectedUserId === null) {
            return;
        }
        deleteUserMutation.mutate(selectedUserId);
        setSelectedUserId(null);
        deleteUserDialog.close();
    };

    const renderPasswordResetDialog = (user: AdminUserDto) => {
        return (
            <ConfirmDialog
                open={passwordResetDialog.isOpen}
                onClose={passwordResetDialog.close}
                item="reset_user_password"
                translate={translate}
                title={translate("confirm_password_reset_title")}
                onConfirm={resetUserPassword}
            >
                {translate("confirm_password_reset_text", {
                    user: formatUser(user, { translate }),
                })}
            </ConfirmDialog>
        );
    };

    // Extract to local variables so linter does not complain about bad dependencies
    const openPasswordResetDialog = passwordResetDialog.open;
    const openEditUserDialog = editUserDialog.open;
    const columns = useMemo<Column<AdminUserDto>[]>(() => {
        return [
            {
                Header: TableHeaderCell,
                accessor: (user: AdminUserDto) => user,
                label: translate("displayname"),
                id: "displayname",
                width: 250,
                sortType: "user",
                Cell: TableUserCell,
            },
            {
                Header: TableHeaderCell,
                id: "realname",
                accessor: "realname",
                sortType: "user",
                label: translate("realname"),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                id: "email",
                accessor: "email",
                label: translate("email"),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: (user) =>
                    groupsQuery.data
                        ?.filter((group) => group.userIds.includes(user.id))
                        .map((group) =>
                            getFullGroupPath(groupsQuery.data ?? [], group)
                                .map((g) => translateFromProperty(g, "name", language))
                                .join(" > "),
                        )
                        .join(", "),
                id: "directGroups",
                label: translate(translationKeys.VDLANG_ADMIN_MEMBERS_GROUPS_DIRECT),
                sortType: compareByStringLength,
                width: 450,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "status",
                id: USER_STATUS_FIELD_NAME,
                translate: translate,
                label: translate("status"),
                headerTooltip: getStatusTooltip(translate),
                Cell: TableConstantsCell,
            },
            {
                Header: TableHeaderCell,
                accessor: (user) => translate(`${translationKeys.VDLANG_USER_TIERS}.${user.tier}`),
                id: "tier",
                label: translate(translationKeys.VDLANG_USER_TIER),
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                label: translate(translationKeys.VDLANG_ADMIN_SIGNON),
                accessor: (user) => (identityProviderQuery.data ?? []).find((sel) => sel.id === user.identityProviderId)?.name ?? "-",
                id: "identityProviderId",
                width: 192,
                disableSortBy: true,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                id: "created_at",
                accessor: "createdAt",
                label: translate("registered_at"),
                Cell: (props) => <TableDateCell nullValue={translate("never")} {...props} />,
            },
            {
                Header: TableHeaderCell,
                id: "invited_at",
                accessor: "invitedAt",
                label: translate("invited_at"),
                Cell: ({ row, value }) => <InvitedAtTableCell status={row.original.status} invitedAt={value} translate={translate} />,
            },
            {
                Header: TableHeaderCell,
                id: "last_login",
                accessor: (user) => (lastLoginsQuery.data ?? []).find((login) => login.userId === user.id)?.lastLogin,
                label: translate("last_login"),
                Cell: (props: { value: Date | null }) => <TableDateCell nullValue={translate("never")} {...props} />,
            },
            {
                Header: TableHeaderCell,
                id: "two_factor_authentication_status",
                accessor: (user) => {
                    const provider = resolveIdentityProvider(user, domains.data ?? [], identityProviderQuery.data ?? []);
                    if (provider?.type !== IdentityProviderType.Password) {
                        return translate(translationKeys.VDLANG_USER_2FA_MANAGED_BY_SIGN_ON);
                    }
                    if (user.twoFactorAuthenticationValidated) {
                        return translate(translationKeys.VDLANG_ACTIVATED);
                    }
                    const config = provider.config as IdentityProviderConfigPassword;
                    if (user.twoFactorAuthenticationEnforce || config.twoFactorAuthenticationEnforce) {
                        return translate(translationKeys.VDLANG_USER_2FA_REQUIRED_BUT_NOT_SETUP);
                    }

                    return translate(translationKeys.VDLANG_NOT_ACTIVATED);
                },
                label: translate(translationKeys.VDLANG_USER_2FA_STATUS),
                Cell: TableTextCell,
            },
            {
                Header: "",
                accessor: "status",
                id: "pwreset",
                disableSortBy: true,
                disableResizing: true,
                width: 64,
                Cell: ({ row, value }) => (
                    <PasswortResetTableCell
                        userId={row.original.id}
                        status={value}
                        translate={translate}
                        resetUserInvitation={resendUserInvitationMutation.mutate}
                        resetPassword={(userId) => {
                            setSelectedUserId(userId);
                            openPasswordResetDialog();
                        }}
                    />
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "edit",
                disableSortBy: true,
                disableResizing: true,
                width: 64,
                Cell: ({ value }) => (
                    <TableSettingsButton
                        title={translate("edit_member")}
                        onClick={() => {
                            setSelectedUserId(value);
                            openEditUserDialog();
                        }}
                    />
                ),
            },
        ];
    }, [
        translate,
        identityProviderQuery.data,
        groupsQuery.data,
        resendUserInvitationMutation.mutate,
        openPasswordResetDialog,
        openEditUserDialog,
        domains.data,
        language,
        lastLoginsQuery.data,
    ]);

    return (
        <Root>
            {userSeatsList.hasSeatLimit ? (
                <SeatLimitContainer>
                    {userSeatsList.admin.hasSeatLimit ? (
                        <SeatLimit
                            title={translate(translationKeys.VDLANG_ADMIN_ADMIN_USER_SEATS_HINT)}
                            seats={userSeatsList.admin.seats}
                            maxSeats={userSeatsList.admin.maxSeats}
                        />
                    ) : null}
                    {userSeatsList.advanced.hasSeatLimit ? (
                        <SeatLimit
                            title={translate(translationKeys.VDLANG_ADMIN_ADVANCED_USER_SEATS_HINT)}
                            seats={userSeatsList.advanced.seats}
                            maxSeats={userSeatsList.advanced.maxSeats}
                        />
                    ) : null}
                    {userSeatsList.basic.hasSeatLimit ? (
                        <SeatLimit
                            title={translate(translationKeys.VDLANG_ADMIN_BASIC_USER_SEATS_HINT)}
                            seats={userSeatsList.basic.seats}
                            maxSeats={userSeatsList.basic.maxSeats}
                        />
                    ) : null}
                    {userSeatsList.light.hasSeatLimit ? (
                        <SeatLimit
                            title={translate(translationKeys.VDLANG_ADMIN_LIGHT_USER_SEATS_HINT)}
                            seats={userSeatsList.light.seats}
                            maxSeats={userSeatsList.light.maxSeats}
                        />
                    ) : null}
                </SeatLimitContainer>
            ) : null}
            <TableRoot>
                <Stack height="100%">
                    {selectedUser != null ? (
                        <EditMemberDialog
                            key={selectedUser.id}
                            user={selectedUser}
                            allUsers={users}
                            isLoggedInUser={selectedUser.id === loggedInUserId}
                            updateUser={updateUser}
                            resetPassword={passwordResetDialog.open}
                            inviteUser={inviteExistingUserMutation.mutate}
                            deleteUser={deleteUserDialog.open}
                            resendUserInvitation={resendUserInvitationMutation.mutate}
                            open={editUserDialog.isOpen}
                            onClose={editUserDialog.close}
                            identityProvider={resolveIdentityProvider(selectedUser, domains.data ?? [], identityProviderQuery.data ?? [])}
                            allGroups={groupsQuery.data ?? []}
                        />
                    ) : null}
                    <InviteMemberDialog
                        open={inviteDialog.isOpen}
                        allUsers={users}
                        inviteUser={inviteUserMutation.mutate}
                        onClose={inviteDialog.close}
                    />
                    {selectedUser !== undefined ? renderPasswordResetDialog(selectedUser) : null}
                    <DeleteDialog
                        open={deleteUserDialog.isOpen}
                        onClose={deleteUserDialog.close}
                        onDelete={deleteUser}
                        translate={translate}
                        item="user"
                    />

                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} px={3} py={1.2}>
                        <SearchInput translate={translate} searchKey={filter} onChange={setFilter} fullWidth={false} />
                        <Typography>{translate(translationKeys.VDLANG_ADMIN_GROUPS_CACHE_HINT)}</Typography>
                        <Button variant="contained" color="primary" onClick={inviteDialog.open} startIcon={<AddIcon />}>
                            {translate("invite_member")}
                        </Button>
                    </Stack>
                    <Divider />
                    <Box flexGrow={1}>
                        <BaseTable
                            fullHeight
                            data={users}
                            columns={columns}
                            defaultSortBy={INITIAL_SORT_BY}
                            defaultCanSort
                            disableSortBy={false}
                            translate={translate}
                            globalFilter={tableFilterValue}
                            noDataText={translate(translationKeys.VDLANG_USER_SELECT_WIDGET_NO_USER_RESULTS)}
                        />
                    </Box>
                </Stack>
            </TableRoot>
        </Root>
    );
};
export default MembersSettings;
