import { AclCondition, AclNamespaces, AdminMeasureConfigListDto, AdminMeasureListDto, DashboardDto, MeasureFieldNames } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { mapConstantsToTranslations } from "../../../../lib/fields";
import { translationKeys } from "../../../../translations/main-translations";
import { AclWithType } from "../AclTable";

function formatFact(condition: AclCondition, t: TFunction): string {
    let factPart = condition.fact;

    switch (condition.fact) {
        case "id":
            factPart = t(translationKeys.VDLANG_ID);
            break;
        case "status":
            factPart = t(translationKeys.VDLANG_ACL_TABLE_STATUS);
            break;
        case "visibility":
            factPart = t("visibility");
            break;
    }

    return factPart;
}

function formatOperator(condition: AclCondition): string {
    let operatorPart = "=";

    switch (condition.operator) {
        case "notEqual":
            operatorPart = "\u{2260}";
            break;
        case "lessThan":
            operatorPart = "<";
            break;
        case "lessThanInclusive":
            operatorPart = "\u{2264}";
            break;
        case "greaterThan":
            operatorPart = ">";
            break;
        case "greaterThanInclusive":
            operatorPart = "\u{2265}";
            break;
    }

    return operatorPart;
}

function formatValue(
    condition: AclCondition,
    t: TFunction,
    namespace: AclNamespaces,
    measures: AdminMeasureListDto,
    measureConfigs: AdminMeasureConfigListDto,
    dashboards: DashboardDto[],
): string {
    if (namespace === AclNamespaces.Process) {
        return formatProcessValue(condition, t, measures);
    } else if (namespace === AclNamespaces.Dashboard && condition.fact === "id") {
        const dashboard = dashboards.find((d) => d.id === condition.value);

        if (dashboard === undefined) {
            return t(translationKeys.VDLANG_ACL_PERMISSION_TABLE_DASHBOARD_NOT_FOUND, { id: condition.value });
        }

        let dashboardName = dashboard?.name;
        if (dashboardName === "VDLANG_DASHBOARD_DEFAULT_NAME") {
            dashboardName = t(translationKeys.VDLANG_DASHBOARD_DEFAULT_NAME, { id: condition.value });
        }
        return `${condition.value} - ${dashboardName}`;
    } else if (namespace === AclNamespaces.Valuestream && condition.fact === "id") {
        const mc = measureConfigs.find((mc) => mc.id === condition.value);

        if (mc === undefined) {
            return t(translationKeys.VDLANG_ACL_PERMISSION_TABLE_VALUESTREAM_NOT_FOUND, { id: condition.value });
        }

        return `${condition.value} - ${t(mc.name)}`;
    }

    return condition.value.toString();
}

function formatProcessValue(condition: AclCondition, t: TFunction, measures: AdminMeasureListDto): string {
    let key = null;
    if (condition.fact === "status") {
        key = mapConstantsToTranslations(MeasureFieldNames.Status, condition.value.toString());
    }
    if (condition.fact === "visibility") {
        key = mapConstantsToTranslations(MeasureFieldNames.Visibility, condition.value.toString());
    }
    if (key !== null) {
        return `"${t(key)}"`;
    }
    if (condition.fact === "id" && condition.operator === "equal") {
        return `${condition.value} - ${measures.find((m) => m.id === condition.value)?.title}`;
    }
    return condition.value.toString();
}

const formatRule = (
    condition: AclCondition,
    t: TFunction,
    acl: AclWithType,
    measures: AdminMeasureListDto,
    measureConfigs: AdminMeasureConfigListDto,
    dashboards: DashboardDto[],
): string => {
    const ruleCount = acl.rule.length;

    if (condition.operator === "*") {
        if (ruleCount === 1) {
            return "*";
        }
        return `${condition.fact} = *`;
    }

    if (ruleCount === 1 && acl.namespace === AclNamespaces.Process && condition.operator === "equal" && condition.fact === "id") {
        const measure = measures.find((m) => m.id === condition.value);
        if (measure === undefined) {
            return t(translationKeys.VDLANG_ACL_PERMISSION_TABLE_PROCESS_NOT_FOUND);
        }
        return `${measure.clientIid} - ${measure.title}`;
    }

    const factPart = formatFact(condition, t);
    const operatorPart = formatOperator(condition);
    const valuePart = formatValue(condition, t, acl.namespace, measures, measureConfigs, dashboards);

    return `${factPart} ${operatorPart} ${valuePart}`;
};

export const useGetAclTableRuleResolver = () => {
    const { t } = useTranslation();

    return useCallback(
        (acl: AclWithType, measures: AdminMeasureListDto, measureConfigs: AdminMeasureConfigListDto, dashboards: DashboardDto[]) =>
            acl.rule.map((r) => formatRule(r, t, acl, measures, measureConfigs, dashboards)).join(", "),
        [t],
    );
};
