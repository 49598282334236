import { UserDto, formatUserName } from "api-shared";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/input/select/Select";
import { translationKeys } from "../../../../translations/main-translations";
import { MAX_OPTIONS_COUNT_PERMISSION_DIALOGS } from "../AddRulesHandling";

type UserSelectProps = {
    value: number[];
    onChange: (value: number[]) => void;
    users?: UserDto[];
};

export const UserSelect = ({ users, value, onChange }: UserSelectProps) => {
    const { t } = useTranslation();

    const userOptions = users
        ?.map((user) => ({
            value: user.id,
            label: formatUserName(user),
        }))
        .toSorted((a, b) => a.label.localeCompare(b.label));

    return (
        <Select
            margin="none"
            label={t(translationKeys.VDLANG_ADMIN_GROUPS_USERS)}
            value={userOptions?.filter((option) => value.includes(option.value))}
            options={userOptions}
            onChange={(o) => onChange(o.map((v) => v.value))}
            menuPortalTarget={document.body}
            placeholder={`${t("Search")}...`}
            isSearchable
            isMulti
            maxOptionsCount={MAX_OPTIONS_COUNT_PERMISSION_DIALOGS}
        />
    );
};
