import { Box, Stack } from "@mui/material";

import { CellProps } from "react-table";
import GroupAvatar from "../usergrouppermissions/GroupAvatar";
import { GroupDto } from "api-shared";
import GroupDetails from "../group/GroupDetails.tsx";

interface ITableGroupCellProps<D extends object = Record<string, unknown>> extends CellProps<D> {
    groups: GroupDto[] | null | undefined;
}

const TableGroupCell = <D extends object = Record<string, unknown>>({
    value,
    groups,
    children,
}: React.PropsWithChildren<ITableGroupCellProps<D>>) => {
    if (value == null) {
        return null;
    }

    const group = groups?.find((g) => g.id === value);

    return (
        <Stack direction="row" spacing={0.875} alignItems="center" maxWidth="100%" useFlexGap>
            {group != null ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <GroupDetails inline groupId={group.id} groups={groups ?? []} />
                    {children}
                </Stack>
            ) : (
                <>
                    <GroupAvatar size={24} />
                    <Box minWidth={0}>{value}</Box>
                    {children}
                </>
            )}
        </Stack>
    );
};

export default TableGroupCell;
